import { FormPageFrame } from "../FormPageFrame/FormPageFrame";

import { Typography, IconButton, Grid, Box } from "@mui/material";

import { TerminApi } from "@sl.is/interim-terminbuchung";

import { theme } from "../../theme";

import type { FC } from "react";
import type { FormPageProps } from "../FormSteps/types";
import type { TerminWrite } from "@sl.is/interim-terminbuchung/dist/esm/models/termin-write";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

export const FormOverviewPage: FC<FormPageProps> = ({
  globalFormValues,
  handleBack,
  goToPage,
  title,
  description,
  onSubmit,
  pageIndex,
  pages,
  stepSummaries,
  setGlobalError,
}) => {
  const terminApi = new TerminApi();
  const [isSending, setIsSending] = useState(false);

  const {
    bemerkung,
    identifikationsnummer,
    anrede,
    vorname,
    nachname,
    telefon,
    email,
    appointmentId,
  } = globalFormValues;

  const fieldsByPage: {
    pageId: number;
    title: string;
    content: (string | string[])[];
  }[] = [
    {
      pageId: 1,
      title: pages[1].title,
      content: stepSummaries[1],
    },
    {
      pageId: 0,
      title: pages[0].title,
      content: stepSummaries[0],
    },
    {
      pageId: 2,
      title: pages[2].title,
      content: [
        `${anrede} ${vorname} ${nachname}`,
        ["Telefon", telefon],
        ["Email", email],
        ["Institutionskennzeichen", identifikationsnummer],
        ["Bemerkungen", bemerkung],
      ],
    },
  ];

  const onSend = () => {
    setIsSending(true);
    const payload: TerminWrite = {
      bemerkung,
      identifikationsnummer,
      ansprechpartner: {
        anrede,
        vorname,
        nachname,
        telefon,
        email,
      },
    };

    terminApi
      .apiTermineIdPatch(appointmentId, payload)
      .then((response) => {
        console.log("apiTermineIdPatch:response", response);
        setIsSending(false);
        onSubmit(pageIndex, {});
      })
      .catch(setGlobalError);
  };

  return (
    <FormPageFrame
      title={title}
      description={description}
      disabledForward={isSending}
      handleForward={() => {
        onSend();
      }}
      handleBack={handleBack}
      forwardButtonLabel="Termin buchen"
      backButtonLabel={pages[pageIndex - 1].title}
    >
      <Grid container spacing={3} rowSpacing={6}>
        {fieldsByPage.map(({ title, content, pageId }) => (
          <Grid item xs={12} sm={6} key={pageId}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h3"
                sx={{
                  mr: 1,
                }}
              >
                {title}
              </Typography>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => goToPage(pageId)}
              >
                <EditIcon
                  sx={{
                    fontSize: theme.spacing(2.5),
                  }}
                />
              </IconButton>
            </Box>

            {content &&
              content.map((data, i) => {
                if (!data) {
                  return null;
                }
                let content;
                if (typeof data === "string") {
                  content = <Typography>{data}</Typography>;
                } else {
                  if (!data[1]) {
                    return null;
                  }

                  content = (
                    <div key={i}>
                      <Typography
                        variant="overline"
                        component="p"
                        sx={{
                          mt: 3,
                        }}
                      >
                        {data[0]}
                      </Typography>
                      <Typography>{data[1]}</Typography>
                    </div>
                  );
                }
                return (
                  <Box
                    key={i}
                    sx={{
                      mb: 1,
                      mt: 1,
                    }}
                  >
                    {content}
                  </Box>
                );
              })}
          </Grid>
        ))}
      </Grid>
    </FormPageFrame>
  );
};
