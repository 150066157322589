import type { FC } from "react";
import { ServiceRead } from "@sl.is/interim-terminbuchung";
import { Card, CardContent, Typography, Box } from "@mui/material";
import styled from "@emotion/styled/macro";
import { theme } from "../../theme";
import CheckIcon from "@mui/icons-material/Check";

export interface RadioCardProps {
  service: ServiceRead;
  onChange: () => void;
  radioButtonName: string;
  checked?: boolean;
}

const StyledCard = styled(Card)`
  height: 100%;
  border: none;

  &:hover,
  &:focus {
    background-color: ${theme.palette.primary.main};
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition-property: background-color, box-shadow;
    transition-duration: ${theme.transitions.duration.short};
    transform-style: ${theme.transitions.easing.easeIn};
  }
`;

const StyledContainer = styled.label`
  cursor: pointer;
  height: 100%;
`;

const StyledIconBackground = styled.div`
  border-radius: ${theme.spacing(0.5)};
  left: 0;
  top: 0;
  height: ${theme.spacing(3)};
  width: ${theme.spacing(3)};
  border: 1px solid ${theme.palette.common.black};
  transition-property: background-color, border-color;
  transition-duration: ${theme.transitions.duration.short};
  transform-style: ${theme.transitions.easing.easeIn};

  ${StyledContainer}:hover & {
    background-color: ${theme.palette.common.white};
    border-color: ${theme.palette.common.white};
  }
`;

// Hidden styled radio input
const StyledRadio = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + ${StyledCard} {
    background-color: ${theme.palette.primary.main};
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    & ${StyledIconBackground} {
      background-color: ${theme.palette.common.white};
      border-color: ${theme.palette.common.white};
      color: ${theme.palette.primary.main};
    }
  }
`;

export const RadioCard: FC<RadioCardProps> = ({
  service,
  onChange,
  radioButtonName,
  checked,
}) => {
  const { name, id, beschreibung, kuerzel } = service;
  return (
    <StyledContainer key={name} title={name}>
      <StyledRadio
        type="radio"
        checked={checked}
        name={radioButtonName}
        value={id}
        onChange={onChange}
      />

      <StyledCard>
        <CardContent
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 6,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  position: "relative",
                  height: theme.spacing(3),
                  width: theme.spacing(3),
                  mr: 1,
                }}
              >
                <StyledIconBackground />
                <Box
                  sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {checked && (
                    <CheckIcon
                      sx={{
                        height: theme.spacing(2),
                        width: theme.spacing(2),
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Typography variant="h4">{name}</Typography>
            </Box>
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  ml: 1,
                }}
              >
                {kuerzel}
              </Typography>
            </Box>
          </Box>

          <Typography variant="body1">{beschreibung}</Typography>
        </CardContent>
      </StyledCard>
    </StyledContainer>
  );
};
