import { useEffect, useState } from "react";

import { Divider, Grid } from "@mui/material";
import { ServiceApi } from "@sl.is/interim-terminbuchung";

import { FormPageFrame } from "../../FormPageFrame/FormPageFrame";
import { RadioCard } from "../../RadioCard/RadioCard";

import type { FormPageProps } from "../types";
import type { FC } from "react";
import type { ServiceRead } from "@sl.is/interim-terminbuchung";

const findElements = (id: string, arr: ServiceRead[]) => {
  let path: string[] = [];
  const find = (id: string, arr: ServiceRead[]) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        path.push(arr[i].id);
        return true;
      }
      if (arr[i].services) {
        if (find(id, arr[i].services)) {
          path.push(arr[i].id);
          return true;
        }
      }
    }
  };

  find(id, arr);

  return path;
};

const serviceApi = new ServiceApi();

export const ServiceStep: FC<FormPageProps> = ({
  onSubmit,
  handleBack,
  pageIndex,
  title,
  description,
  globalFormValues,
  setGlobalError,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [stepSummary, setStepSummary] = useState<string>(null);

  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [services, setServices] = useState<ServiceRead[][]>([]);

  const getLatestArrayEl = (arr: any[]) => arr[arr.length - 1];

  useEffect(() => {
    serviceApi
      .apiServiceGetCollection()
      .then((response) => {
        const services = response?.data;
        const savedServices = findElements(
          globalFormValues.serviceIdNumber,
          services
        ).reverse();

        if (savedServices.length) {
          const newServices = [services];

          for (let i = 0; i < savedServices.length; i++) {
            const service = services.find((s) => s.id === savedServices[i]);
            if (service?.services?.length) {
              newServices[i + 1] = service.services;
            }
          }

          setServices([...newServices]);
          setSelectedServices(savedServices);
          setStepSummary(getLatestArrayEl(savedServices));
        } else {
          setServices([services]);
        }

        setIsLoading(false);
      })
      .catch(setGlobalError);
  }, [globalFormValues.serviceIdNumber, setGlobalError]);

  const submitForm = () => {
    onSubmit(
      pageIndex,
      { serviceIdNumber: getLatestArrayEl(selectedServices) },
      [stepSummary]
    );
  };

  const submitDisabled = selectedServices.length !== services.length;

  return (
    <FormPageFrame
      title={title}
      description={description}
      handleForward={submitForm}
      handleBack={handleBack}
      loading={isLoading}
      disabledForward={submitDisabled}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        {services.map((servicesRow, serviceLevel) => {
          return (
            <div key={serviceLevel}>
              {Boolean(serviceLevel) && <Divider sx={{ mb: 6 }}></Divider>}
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                sx={{
                  mb: 6,
                }}
              >
                {servicesRow.map((service) => {
                  return (
                    <Grid item xs={12} md={6} lg={4} key={service.id}>
                      <RadioCard
                        radioButtonName={`service_${serviceLevel}`}
                        service={service}
                        checked={selectedServices[serviceLevel] === service.id}
                        onChange={() => {
                          // If has children, set children as next level
                          if (service.services.length) {
                            const newSsservices = [...services];
                            newSsservices[serviceLevel + 1] = service.services;
                            setServices(newSsservices);

                            // If it has no children, set the current service as selected
                          } else {
                            const newServices = services.slice(
                              0,
                              serviceLevel + 1
                            );
                            setServices(newServices);
                            setStepSummary(service.name);
                          }

                          const newSelectedServices = [
                            ...selectedServices,
                          ].slice(0, serviceLevel + 1);
                          newSelectedServices[serviceLevel] = service.id;
                          setSelectedServices(newSelectedServices);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })}
        {!submitDisabled && <input type="submit" hidden />}
      </form>
    </FormPageFrame>
  );
};
