import { useFormik } from "formik";
import * as Yup from "yup";

import {
  TextField as MuiTextField,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";

import { FormPageFrame } from "../../FormPageFrame/FormPageFrame";
import { ContactFormSection } from "./ContactFormSection/ContactFormSection";

import type { FormPageProps, FormikValues, FieldNames } from "../types";
import type { FC } from "react";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { capitalize } from "../../../utils/utils";

type TextFieldProps = MuiTextFieldProps & {
  fieldName: FieldNames;
  formik: ReturnType<typeof useFormik<FormikValues>>;
};

const TextField: FC<TextFieldProps> = ({
  fieldName,
  formik: { values, handleChange, touched, errors },
  ...rest
}) => (
  <MuiTextField
    color="secondary"
    key={fieldName}
    fullWidth
    id={fieldName}
    name={fieldName}
    label={capitalize(fieldName)}
    value={values[fieldName]}
    onChange={handleChange}
    error={touched[fieldName] && Boolean(errors[fieldName])}
    helperText={touched[fieldName] && errors[fieldName]}
    {...rest}
  ></MuiTextField>
);

const errorTexts = {
  required: {
    telefon: "Bitte geben Sie nur Ziffern ein.",
    email: "Bitte tragen Sie Ihren E-Mail ein.",
    vorname: "Bitte tragen Sie Ihren Vornamen ein.",
    nachname: "Bitte tragen Sie Ihren Nachnamen ein.",
    identifikationsnummer: "Bitte tragen Sie Ihre Identifikationsnummer ein.",
    anrede: "Bitte wählen Sie eine Anrede aus.",
  },
  wrongFormat: {
    email: "Die Angabe muss im E-Mail-Format sein.",
  },
};

export const ContactInformationStep: FC<FormPageProps> = ({
  onSubmit,
  handleBack,
  pageIndex,
  title,
  description,
  globalFormValues,
  pages,
}) => {
  const formik = useFormik<FormikValues>({
    initialValues: {
      anrede: globalFormValues.anrede || "",
      nachname: globalFormValues.nachname || "",
      vorname: globalFormValues.vorname || "",
      email: globalFormValues.email || "",
      bemerkung: globalFormValues.bemerkung || "",
      identifikationsnummer: globalFormValues.identifikationsnummer || "",
      telefon: globalFormValues.telefon || "",
    },
    validationSchema: Yup.object({
      anrede: Yup.string().required(errorTexts.required.anrede),
      nachname: Yup.string().required(errorTexts.required.nachname),
      vorname: Yup.string().required(errorTexts.required.vorname),
      email: Yup.string()
        .required(errorTexts.required.email)
        .matches(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          errorTexts.wrongFormat.email
        ),

      bemerkung: Yup.string(),
      identifikationsnummer: Yup.string().required(
        errorTexts.required.identifikationsnummer
      ),
      telefon: Yup.string().required(errorTexts.required.telefon),
    }),
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(pageIndex, values, [
        `${values.anrede} ${values.vorname} ${values.nachname}`,
        `${values.telefon}`,
      ]);
      setSubmitting(false);
    },
  });

  const { handleSubmit, submitForm } = formik;

  return (
    <FormPageFrame
      title={title}
      description={description}
      handleForward={submitForm}
      handleBack={handleBack}
      backButtonLabel={pages[pageIndex - 1].title}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ContactFormSection title="Ansprechpartner">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fieldName="anrede" formik={formik} select required>
                    {["Herr", "Frau", "Divers"].map((value) => (
                      <MenuItem value={value} key={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <TextField fieldName="vorname" formik={formik} required />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <TextField fieldName="nachname" formik={formik} required />
                </Grid>
                <Grid item xs={12}>
                  <TextField fieldName="email" formik={formik} required />
                </Grid>
                <Grid item xs={12}>
                  <TextField fieldName="telefon" formik={formik} required />
                </Grid>
              </Grid>
            </ContactFormSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactFormSection title="Bemerkungen">
              <TextField
                fieldName="bemerkung"
                multiline
                minRows={7}
                formik={formik}
              />
            </ContactFormSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactFormSection title="Weitere Informationen">
              <Typography
                variant="overline"
                component="p"
                sx={{
                  marginBottom: 3,
                }}
              >
                Geben Sie gerne noch Ihre Betriebsstättennummer oder das
                Institutionskennzeichen an.
              </Typography>
              <TextField
                fieldName="identifikationsnummer"
                label="Weitere Angaben"
                formik={formik}
                required
              />
            </ContactFormSection>
          </Grid>
        </Grid>
      </form>
    </FormPageFrame>
  );
};
