import { Typography } from "@mui/material";
import styled from "@emotion/styled/macro";

import { theme } from "../../theme";

import type { FC, ReactNode } from "react";

export interface TimeWidgetWrapperProps {
  label: string;
  children: ReactNode;
}

const Wrapper = styled.div`
  flex: 0 0 ${theme.spacing(40)};
  border: 1px solid ${theme.palette.grey[300]};
  background-color: ${theme.palette.common.white}; // TODO: Use theme units
  border-radius: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(1)};
  padding-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(2)};

  &:not(:last-child) {
    margin-right: ${theme.spacing(2)};
  }
`;

export const TimeWidgetWrapper: FC<TimeWidgetWrapperProps> = ({
  label,
  children,
}) => (
  <Wrapper>
    <Typography variant="h2" pl={3} pr={2} pt={2}>
      {label}
    </Typography>
    {children}
  </Wrapper>
);
