import type { Theme } from "@mui/material/styles";
export const MuiStepper = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      [theme.breakpoints.down("md")]: {
        flexDirection: "row" as const,
        overflowX: "scroll",
      },
    }),
  },
};
