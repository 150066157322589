import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import styled from "@emotion/styled/macro";

import { TerminApi } from "@sl.is/interim-terminbuchung";

import { TimePicker } from "../../TimePicker/TimePicker";
import { DatePicker } from "../../DatePicker/DatePicker";
import { TimeWidgetWrapper } from "../../TimeWidgetWrapper/TimeWidgetWrapper";
import { FormPageFrame } from "../../FormPageFrame/FormPageFrame";

import { locale } from "../../../constants/locale";
import { dateToString, dateTimeToTimeString } from "../../../utils/utils";

import type { FC } from "react";
import type { FormPageProps, FormikValues } from "../types";
import type { TerminList } from "@sl.is/interim-terminbuchung";

const terminApi = new TerminApi();

const formatStepSummary = (date: string, time: string): string[] => {
  const durationMin = 30;
  const duration = durationMin * 60000;
  const startDate = new Date(`${date} ${time}`);
  const dayOfWeek = startDate.toLocaleString(locale, { weekday: "long" });
  const dayOfMonth = startDate.getDate();
  const fullMonth = startDate.toLocaleString(locale, { month: "long" });
  const year = startDate.getFullYear();
  const endDate = new Date(startDate.getTime() + duration);
  const endTime = dateTimeToTimeString(endDate);

  return [
    `${dayOfWeek}, ${dayOfMonth} ${fullMonth} ${year}`,
    `${time} — ${endTime} Uhr`,
  ];
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
`;

export const DateTimeStep: FC<FormPageProps> = ({
  onSubmit,
  handleBack,
  pageIndex,
  globalFormValues,
  title,
  description,
  pages,
  setGlobalError,
}) => {
  const { serviceIdNumber } = globalFormValues;
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableTimes, setAvailableTimes] = useState<
    {
      time: string;
      itemId: string;
    }[]
  >([]);
  const { setFieldValue, values, touched, errors, submitForm } =
    useFormik<FormikValues>({
      initialValues: {
        time: "",
        date: "",
        appointmentId: "",
      },
      validationSchema: Yup.object({
        time: Yup.string().required("Required"),
        date: Yup.date().required("Required"),
        appointmentId: Yup.string(),
      }),
      onSubmit: (values, { setSubmitting }) => {
        onSubmit(
          pageIndex,
          values,
          formatStepSummary(values.date, values.time)
        );
        setSubmitting(false);
      },
    });

  useEffect(() => {
    terminApi
      .apiTermineverfuegbarkeitGetCollection(
        1,
        undefined,
        undefined,
        new Date().toISOString().split("T")[0],
        undefined,
        `/services/${serviceIdNumber}`
      )
      .then((response) => {
        const days = response?.data[0].verfuegbareTage;

        setAvailableDates(days);

        const formattedToday = dateToString(new Date());

        if (days.includes(globalFormValues.date)) {
          setFieldValue("date", globalFormValues.date);
        } else if (days.includes(formattedToday)) {
          setFieldValue("date", formattedToday);
        }
      })
      .catch(setGlobalError);
  }, [setFieldValue, globalFormValues.date, serviceIdNumber, setGlobalError]);

  useEffect(() => {
    if (!values.date) return;

    terminApi
      .apiTermineGetCollection(
        1,
        undefined,
        undefined,
        values.date,
        undefined,
        `/services/${serviceIdNumber}`
      )
      .then((response) => {
        const appointmentsList = response?.data
          .map((timeSlot: TerminList) => ({
            time: dateTimeToTimeString(new Date(timeSlot.datum)),
            itemId: timeSlot.id,
          }))
          .sort((a, b) => (a.time < b.time ? -1 : 1));

        setAvailableTimes(appointmentsList);

        const currentChosenTime =
          globalFormValues.time &&
          appointmentsList.find((a) => a.time === globalFormValues.time);

        if (currentChosenTime) {
          setFieldValue("time", currentChosenTime.time);
          setFieldValue("appointmentId", currentChosenTime.itemId);
        }
      })
      .catch(setGlobalError);
  }, [
    values.date,
    globalFormValues.time,
    setFieldValue,
    serviceIdNumber,
    setGlobalError,
  ]);

  return (
    <FormPageFrame
      title={title}
      description={description}
      disabledForward={!values.time || !values.date}
      handleForward={submitForm}
      handleBack={handleBack}
      backButtonLabel={pages[pageIndex - 1].title}
    >
      <Container>
        <TimeWidgetWrapper label="Datum">
          <DatePicker
            disablePast
            className="date-picker"
            displayStaticWrapperAs="desktop"
            onChange={(date) => {
              setFieldValue("date", dateToString(date));
            }}
            renderInput={(params) => <TextField {...params}></TextField>}
            value={values.date}
            views={["day"]}
            shouldDisableDate={(date: Date) => {
              // get date in yyyy-mm-dd format
              const dateString = dateToString(date);
              return !availableDates.includes(dateString);
            }}
          />
        </TimeWidgetWrapper>
        <TimeWidgetWrapper label="Uhrzeit">
          <TimePicker
            name="time"
            label="Uhrzeit"
            timeSlots={availableTimes}
            value={values["time"]}
            error={touched["time"] && Boolean(errors["time"])}
            helperText={touched["time"] && errors["time"]}
            onChange={({ timeSlot, itemId }) => {
              setFieldValue("time", timeSlot);
              setFieldValue("appointmentId", itemId);
            }}
          />
        </TimeWidgetWrapper>
      </Container>
    </FormPageFrame>
  );
};
