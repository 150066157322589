import type { FC, ReactNode } from "react";
import { Paper, Typography } from "@mui/material";

export interface ContactFormSectionProps {
  children: ReactNode;
  title: string;
}

export const ContactFormSection: FC<ContactFormSectionProps> = ({
  children,
  title,
}) => (
  <Paper
    sx={{
      height: "100%",
      padding: 2,
    }}
  >
    <Typography
      sx={{
        marginBottom: 2,
      }}
      variant="h2"
    >
      {title}
    </Typography>
    {children}
  </Paper>
);
