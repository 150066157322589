import { Button, Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styled from "@emotion/styled/macro";

import {
  ChildrenSkeleton,
  ButtonsSkeleton,
  HeadlineSkeleton,
} from "./Skeletons";

import type { ReactNode, FC } from "react";

export interface FormPageFrameProps {
  handleBack?: (args: any) => void;
  forwardButtonLabel?: string;
  backButtonLabel?: string;
  handleForward?: (args: any) => void;
  title: string;
  description?: string;
  loading?: boolean;
  children: ReactNode;
  disabledForward?: boolean;
}

const ButtonContainer = styled.div`
  padding: 20px 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const FormPageFrame: FC<FormPageFrameProps> = ({
  forwardButtonLabel = "Weiter",
  backButtonLabel = "Zurück",
  handleBack,
  handleForward,
  disabledForward,
  children,
  title,
  description,
  loading,
}) => {
  const Buttons = () => (
    <>
      {handleBack && (
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          startIcon={<ChevronLeftIcon />}
        >
          {backButtonLabel}
        </Button>
      )}

      {handleForward && (
        <Button
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleForward}
          endIcon={<ChevronRightIcon />}
          disabled={disabledForward}
        >
          {forwardButtonLabel}
        </Button>
      )}
    </>
  );

  return (
    <Box
      sx={{
        borderRadius: ({ slisCustomVars }: any) =>
          slisCustomVars.borderRadiusSmallFactor,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          padding: { xs: 2, md: 4 },
          paddingBottom: 0,
        }}
      >
        <Typography variant="h1" component="h2">
          {loading ? <HeadlineSkeleton /> : title}
        </Typography>
        {!loading && description && (
          <Typography
            variant="body1"
            sx={{
              mt: 2,
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          padding: { xs: 2, md: 4 },
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>{loading ? <ChildrenSkeleton /> : children}</div>
        <ButtonContainer>
          {loading ? <ButtonsSkeleton /> : <Buttons />}
        </ButtonContainer>
      </Box>
    </Box>
  );
};
