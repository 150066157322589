import { BORDER_RADIUS_SMALL } from "../constants";
import type { Theme } from "@mui/material/styles";

export const MuiStep = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      background: theme.palette.background.paper,
      borderRadius: BORDER_RADIUS_SMALL,
      padding: 20,
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: theme.palette.divider,
      marginBottom: theme.spacing(2),

      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    }),
  },
};
