export const MuiInputBase = {
  styleOverrides: {
    input: {
      border: "none !important",
      boxSizing: "unset" as const,

      "&input": {
        height: "1.4375em !important",
      },
    },
    inputMultiline: {
      borderRadius: 0,
    },
  },
};
