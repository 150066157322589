import { createTheme } from "@mui/material/styles";
import { MuiStepConnector } from "./MuiStepper/MuiStepConnector";
import { MuiStepLabel } from "./MuiStepper/MuiStepLabel";
import { MuiStep } from "./MuiStepper/MuiStep";
import { MuiStepper } from "./MuiStepper/MuiStepper";

import { MuiButtonBase } from "./MuiButtonBase";
import { MuiInputBase } from "./MuiInputBase";
import { MuiButton } from "./MuiButton";
import { MuiPaper } from "./MuiPaper";
import { typography } from "./typography";

import {
  BORDER_RADIUS,
  BORDER_RADIUS_SMALL,
  BORDER_RADIUS_SMALL_FACTOR,
} from "./constants";

declare module "@mui/material/styles" {
  interface CustomTheme {
    slisCustomVars?: {
      borderRadiusSmall: number;
      borderRadiusSmallFactor: number;
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export const theme = createTheme({
  // spacing: (factor: number) => `${0.25 * factor}rem`,
  slisCustomVars: {
    borderRadiusSmall: BORDER_RADIUS_SMALL,
    borderRadiusSmallFactor: BORDER_RADIUS_SMALL_FACTOR,
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  palette: {
    primary: {
      main: "#ffbb00",
      light: "#ffc525",
      dark: "#FFD666",
    },
    secondary: {
      main: "#444",
      light: "#888",
      dark: "#000",
    },
    text: {
      primary: "#1D1D1B",
    },
  },
  typography,
  components: {
    MuiButtonBase,
    MuiButton,
    MuiPaper,
    MuiStepConnector,
    MuiStepLabel,
    MuiStep,
    MuiStepper,
    MuiInputBase,
  },
});
