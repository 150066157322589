import styled from "@emotion/styled/macro";

import {
  Stepper as MUIStepper,
  StepLabel,
  Typography,
  Step as MuiStep,
  StepIcon,
  Box,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "@mui/icons-material/Check";

import { theme } from "../../theme";

import type { FC } from "react";
import type { StepIconProps } from "@mui/material";

export interface StepData {
  title: string;
  description?: string;
  content?: string;
  completed?: boolean;
}

interface StepperProps {
  steps: StepData[];
  stepSummaries: string[][];
  activePage: number;
}

const StepSummary = styled.div`
  margin-top: ${theme.spacing(0.5)}};
`;

const StepperIcon: FC<StepIconProps> = ({
  active,
  completed,
  icon,
  ...props
}) => {
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));

  const completedIcon = (
    <CheckIcon
      sx={{
        height: theme.spacing(2),
        width: theme.spacing(2),
      }}
    />
  );
  const content = completed && matchesMd ? completedIcon : icon;
  return (
    <StepIcon
      {...props}
      icon={
        <Box
          sx={{
            position: "relative",
            height: theme.spacing(3),
            width: theme.spacing(3),
          }}
        >
          <Box
            sx={{
              borderRadius: theme.spacing(0.5),
              left: 0,
              top: 0,
              height: theme.spacing(3),
              width: theme.spacing(3),
              border: "1px solid",
              borderColor:
                completed || active
                  ? theme.palette.primary.main
                  : theme.palette.grey[400],
              backgroundColor: active && theme.palette.primary.main,
            }}
          />
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              height: theme.spacing(3),
              width: theme.spacing(3),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {content}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export const Stepper: FC<StepperProps> = ({
  steps,
  activePage,
  stepSummaries,
}) => {
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MUIStepper activeStep={activePage} orientation="vertical">
      {steps.map(({ description, content, title, completed }, i) => (
        <MuiStep key={title} completed={completed}>
          <StepLabel
            StepIconComponent={StepperIcon}
            optional={
              matches &&
              stepSummaries[i] && (
                <StepSummary>
                  {stepSummaries[i].map((textLine) => (
                    <Typography key={textLine} variant="body2">
                      {textLine}
                    </Typography>
                  ))}
                </StepSummary>
              )
            }
          >
            <Typography
              sx={{
                lineHeight: 1.5,
              }}
              variant="h4"
            >
              {title}
            </Typography>
          </StepLabel>
        </MuiStep>
      ))}
    </MUIStepper>
  );
};
