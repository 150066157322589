import type { Theme } from "@mui/material/styles";

export const MuiButton = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      textTransform: "none" as const, // https://github.com/mui/material-ui/issues/16307#issuecomment-902344903
    },

    startIcon: ({ theme }: { theme: Theme }) => ({
      marginLeft: theme.spacing(-1.5),
      marginRight: theme.spacing(0.5),
    }),

    endIcon: ({ theme }: { theme: Theme }) => ({
      marginRight: theme.spacing(-1.5),
      marginLeft: theme.spacing(0.5),
    }),
  },
};
