import { Skeleton } from "@mui/material";

export const HeadlineSkeleton = () => (
  <Skeleton
    variant="rounded"
    sx={{ backgroundColor: "grey.500", width: "60%" }}
  />
);

export const ChildrenSkeleton = () => (
  <>
    <Skeleton sx={{ height: "120px" }} />
    <Skeleton />
    <Skeleton width="60%" />
  </>
);

export const ButtonsSkeleton = () => (
  <>
    <Skeleton width="150px" height="70px" />
    <Skeleton width="150px" height="70px" />
  </>
);
