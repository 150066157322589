import { BORDER_RADIUS_SMALL } from "./constants";
import { Theme } from "@mui/material/styles";

export const MuiPaper = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      boxShadow: "none",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.divider,
      borderRadius: BORDER_RADIUS_SMALL,
    }),
  },
};
