export const typography = {
  fontFamily: "Inter, sans-serif",
  h1: {
    fontSize: 24,
    fontWeight: 600,
  },
  h2: {
    fontSize: 20,
    fontWeight: 500,
  },
  h3: {
    fontSize: 18,
    fontWeight: 600,
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
  },
  button: {
    fontSize: 16,
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
  },
  overline: {
    textTransform: "none" as const,
    opacity: 0.6,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.4,
  },
};
