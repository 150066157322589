import type { FC } from "react";

import { Typography, Box, Button } from "@mui/material";

import { theme } from "../../theme";

export interface GlobalErrorProps {
  resetForm: () => void;
}

const absolutelyCenteredStyles = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: "auto",
};

export const GlobalError: FC<GlobalErrorProps> = ({ resetForm }) => (
  <Box
    sx={{
      ...absolutelyCenteredStyles,
    }}
  >
    <Box
      sx={{
        ...absolutelyCenteredStyles,
        background: theme.palette.grey[600],
        opacity: 0.5,
      }}
    ></Box>
    <Box
      sx={{
        ...absolutelyCenteredStyles,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: theme.spacing(50),
        background: theme.palette.background.paper,
        width: "70%",
        minWidth: theme.spacing(35),
        maxWidth: theme.spacing(100),
        padding: theme.spacing(3),
        borderRadius: theme.slisCustomVars.borderRadiusSmallFactor,
      }}
    >
      <Typography variant="h1">
        Ups!!!
        <br />
        Da ist etwas schief gelaufen...
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button color="secondary" variant="contained" onClick={resetForm}>
          Neue starten
        </Button>
      </Box>
    </Box>
  </Box>
);
