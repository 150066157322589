import type { Theme } from "@mui/material/styles";

export const MuiStepLabel = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      padding: 0,
      margin: 0,
      display: "flex",
      flexDirection: "row" as const,
      alignItems: "flex-start" as const,
      color: theme.palette.grey[500],

      "& .Mui-active, & .Mui-completed": {
        color: theme.palette.text.primary,
      },
    }),
    iconContainer: ({ theme }: { theme: Theme }) => ({
      [theme.breakpoints.down("md")]: {
        "&:not(.Mui-active)": {
          padding: 0,
        },
      },
    }),
    labelContainer: ({ theme }: { theme: Theme }) => ({
      "& .Mui-active, & .Mui-completed": {
        color: theme.palette.text.primary,
      },
    }),

    label: ({ theme }: { theme: Theme }) => ({
      [theme.breakpoints.down("md")]: {
        display: "none",
        "&.Mui-active": {
          display: "block",
        },
      },
    }),
  },
};
