import { FormPageFrame } from "../FormPageFrame/FormPageFrame";
import { Button, Typography } from "@mui/material";

import type { FC } from "react";
import type { FormPageProps } from "../FormSteps/types";

export const FormSuccessPage: FC<FormPageProps> = ({
  title,
  description,
  resetForm,
}) => (
  <FormPageFrame title={title} description={description}>
    <Button variant="outlined" color="secondary" onClick={resetForm}>
      Weiteren Termin buchen
    </Button>

    <br />
    <Typography
      variant="overline"
      component="p"
      sx={{
        mt: 4,
      }}
    >
      Sie haben noch eine Frage zu Ihrer Buchung?
      <br />
      Dann kontaktieren Sie uns unter
      <br />
      [Kontakt-Mailadresse für Buchungsfragen].
    </Typography>
  </FormPageFrame>
);
