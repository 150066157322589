import { styled } from "@mui/material/styles";
import { StaticDatePicker as MUiStaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import de from "date-fns/locale/de";
import { deDE } from "@mui/x-date-pickers";

import type { RefAttributes } from "react";
import type { StaticDatePickerProps } from "@mui/x-date-pickers/StaticDatePicker/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

declare type StaticDatePickerComponent = (<TInputDate, TDate = TInputDate>(
  props: StaticDatePickerProps<TInputDate, TDate> &
    RefAttributes<HTMLDivElement>
) => JSX.Element) & {
  propTypes?: any;
};

const StyledStaticDatePicker = styled(MUiStaticDatePicker)`
  & .MuiPickersDay-today {
    position: relative;
    border: none !important;
  }

  & .MuiPickersDay-today::after {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: currentColor;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const StaticDatePicker = StyledStaticDatePicker as StaticDatePickerComponent;

export const DatePicker: StaticDatePickerComponent = ({ ...props }) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={de}
    localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <StaticDatePicker
      {...props}
      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() + day.slice(1)}
    />
  </LocalizationProvider>
);
