import { useState } from "react";

import { Grid, Container } from "@mui/material";

import { Stepper } from "../Stepper/Stepper";
import { ServiceStep } from "../FormSteps/ServiceStep/ServiceStep";
import { DateTimeStep } from "../FormSteps/DateTimeStep/DateTimeStep";
import { ContactInformationStep } from "../FormSteps/ContactInformationStep/ContactInformationStep";
import { FormOverviewPage } from "../FormOverviewPage/FormOverviewPage";
import { FormSuccessPage } from "../FormSuccessPage/FormSuccessPage";
import { GlobalError } from "../GlobalError/GlobalError";

import type {
  FormPageProps,
  FormikValues,
  OnStepSubmit,
} from "../FormSteps/types";
import type { FC } from "react";

export interface FormPage {
  title: string;
  description?: string;
  component: FC<FormPageProps>;
}

export type StepSummaries = string[][];

export const Form = () => {
  const pages: FormPage[] = [
    {
      title: "Service",
      description: "Welche Art von Termin möchten Sie buchen?",
      component: ServiceStep,
    },
    {
      title: "Termin",
      description:
        "Bitte wählen Sie einen Zeitpunkt für Ihren Termin. Wählen Sie dafür Datum und Uhrzeit aus.",
      component: DateTimeStep,
    },
    {
      title: "Kontaktdaten",
      description:
        "Wie können wir Sie erreichen? Füllen Sie bitte das Formular aus.",
      component: ContactInformationStep,
    },
    {
      title: "Überblick",
      description:
        "Wie können wir Sie erreichen? Füllen Sie bitte das Formular aus.",
      component: FormOverviewPage,
    },
  ];

  const [values, setValues] = useState<FormikValues>({});
  const [activePageIndex, setActiveStepIndex] = useState(0);
  const [stepSummaries, setStepSummaries] = useState<StepSummaries>([]);
  const [globalError, setGlobalError] = useState<boolean>(false);

  const handleNext = () => {
    if (!setActiveStepIndex) {
      return;
    }
    setActiveStepIndex(activePageIndex + 1);
  };

  const handleBack = () => {
    if (!setActiveStepIndex) {
      return;
    }

    setActiveStepIndex(activePageIndex - 1);
  };

  const goToPage = (i: number) => {
    setActiveStepIndex(i);
  };

  const resetForm = () => {
    setGlobalError(false);
    setValues({});
    setActiveStepIndex(0);
    setStepSummaries([]);
  };

  const onStepSubmit: OnStepSubmit = (
    stepIndex: number,
    payload: FormikValues,
    stepSummary: string[]
  ) => {
    setValues({
      ...values,
      ...payload,
    });
    handleNext();
    setStepSummaries((prev) => {
      const newSummaries = [...prev];
      newSummaries[stepIndex] = stepSummary;
      return newSummaries;
    });
  };

  let onBackClick;

  if (activePageIndex !== 0) {
    onBackClick = handleBack;
  }

  let CurrentStepComponent;
  let title;
  let description;

  // if equals last page +1 it's a success page 🎉
  if (activePageIndex === pages.length) {
    CurrentStepComponent = FormSuccessPage;
    title = "Danke für Ihre Buchung!";
    description =
      "Sie erhalten von uns in Kürze eine E-Mail mit allen Informationen zu Ihrem Termin.";
  } else {
    CurrentStepComponent = pages[activePageIndex].component;
    title = pages[activePageIndex].title;
    description = pages[activePageIndex].description;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        position: "relative",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          pointerEvents: globalError ? "none" : "auto",
        }}
      >
        <Grid item xs={12} md={3}>
          <Stepper
            steps={pages}
            stepSummaries={stepSummaries}
            activePage={activePageIndex}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <CurrentStepComponent
            setGlobalError={() => setGlobalError(true)}
            resetForm={resetForm}
            stepSummaries={stepSummaries}
            pages={pages}
            title={title}
            description={description}
            globalFormValues={values}
            pageIndex={activePageIndex}
            onSubmit={onStepSubmit}
            handleBack={onBackClick}
            goToPage={goToPage}
          />
        </Grid>
      </Grid>
      {globalError && <GlobalError resetForm={resetForm} />}
    </Container>
  );
};
