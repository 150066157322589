import styled from "@emotion/styled/macro";

import type { FC } from "react";

import { Button, Grid, Typography } from "@mui/material";

import { theme } from "../../theme";

export interface TimePickerProps {
  timeSlots: {
    time: string;
    itemId: string;
  }[];
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string | boolean;
  value?: string;
  onChange: (args: { timeSlot: string; itemId: string }) => void;
  shouldDisableTimeSlot?: (timeSlot: string) => boolean;
}

const RadioInput = styled.input`
  display: none;
`;

const RadioLabel = styled.label`
  display: block;
`;

const TimepickerWrapper = styled.div`
  display: flex;
  padding: ${theme.spacing(3)};
  padding-top: ${theme.spacing(2)};
  flex-wrap: wrap;
`;

export const TimePicker: FC<TimePickerProps> = ({
  onChange,
  name,
  value,
  timeSlots,
}) => {
  return (
    <TimepickerWrapper>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {Boolean(!timeSlots.length) && (
            <Typography variant="overline">Kein Termin verfügbar</Typography>
          )}
        </Grid>
        {timeSlots.map(({ time, itemId }) => {
          return (
            <Grid item xs={3} key={itemId}>
              <RadioLabel>
                <RadioInput
                  type="radio"
                  name={name}
                  value={value}
                  checked={time === value}
                  onChange={() => {
                    onChange({ timeSlot: time, itemId: itemId });
                  }}
                />
                <Button
                  component="span"
                  sx={{
                    height: theme.spacing(4),
                    width: "100%",
                  }}
                  variant={time === value ? "contained" : "outlined"}
                  color={time === value ? "primary" : "secondary"}
                  size="small"
                >
                  {time}
                </Button>
              </RadioLabel>
            </Grid>
          );
        })}
      </Grid>
    </TimepickerWrapper>
  );
};
