export const dateToString = (date: Date) => {
  const day = date.getDate();
  const month =
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

// function takes a date and returns time in hh:mm format
export const dateTimeToTimeString = (date: Date) => {
  const addLeadingZero = (str: string) => (str.length === 1 ? `0${str}` : str);

  const hours = addLeadingZero(String(date.getHours()));
  const minutes = addLeadingZero(String(date.getMinutes()));

  return `${hours}:${minutes}`;
};

export const capitalize = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
